<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ spot.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_NAME") }}</dt>
          <dd>
            {{ spot.name }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
        >
          <dt>{{ $t("SPOTS.SPOT_TYPE") }}</dt>
          <dd>
            <spot-type :spotType="spot.spotType" />
          </dd>
        </dl>
        <dl class="row full" v-if="spot.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="spot.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CATEGORY") }}</dt>
          <dd>
            <spot-category
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
              :spotCategory="spot.category"
            />
            <span v-else>{{ spot.category?.name }}</span>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ spot.created_at | moment("DD/MM/YYYY") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_BY") }}</dt>
          <dd>{{ spot.createdBy.firstname }} {{ spot.createdBy.lastname }}</dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ spot.updated_at | moment("DD/MM/YYYY") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import SpotCategory from "@/components/SpotCategory";
import SpotType from "@/components/SpotType";

export default {
  name: "spot-view-global",

  props: ["spot"],

  components: { SpotCategory, SpotType },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    spotUpdated() {
      this.$emit("spotUpdated", true);
    },
  },

  mounted() {},

  watch: {
    spot(spot) {},
  },
};
</script>
