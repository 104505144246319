<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!spot">
      <span class="loader"></span>
    </span>
    <spot-form
      v-if="spot"
      :loading="loading"
      :spotData="spot"
      :formErrors="formErrors"
      @spotSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotModal="onCloseSpotModal"
    />
  </div>
</template>

<script>
import SpotForm from "../partials/SpotForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SpotForm },

  mixins: [alertLeave],

  props: {
    spotId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      spot: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("spots/get", this.spotId);
        this.spot = this.$store.getters["spots/spot"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(spot) {
      this.loading = true;
      const spotData = cloneDeep(spot);

      try {
        await this.$store.dispatch("spots/update", spotData);
        this.$notify({
          type: "success",
          message: this.$t("SPOTS.SPOT_UPDATED"),
        });
        const spot = await this.$store.getters["spots/spot"];
        this.$emit("onViewSpot", spot, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseSpotModal() {
      this.$emit("onCloseSpotModal");
    },
  },
};
</script>
