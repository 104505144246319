<template>
  <div class="kw-tabs-wrapper-content">
    <div class="campsite-sheet">
      <h3>{{ $t("SPOTS.SPOT_CAPACITY") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_NAME") }}</dt>
            <dd>
              {{ spot.name }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}</dt>
            <dd>
              {{ spot.children_capacity }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}</dt>
            <dd>
              {{ spot.adults_capacity }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_PET_CAPACITY") }}</dt>
            <dd>
              {{ spot.pets_capacity }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="campsite-sheet">
      <h3>{{ $t("COMMON.LOCATION") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_LENGTH") }}</dt>
            <dd>
              {{ spot.equipment_length }}
            </dd>
          </dl>
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_TYPE") }}</dt>
            <dd>
              {{ spot.spotType.name }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="campsite-sheet">
      <h3>{{ $t("SPOTS.SPOT_SCHEDULE") }}</h3>
      <div class="campsite-sheet-item">
        <div class="row">
          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_ARRIVAL_TIME") }}</dt>
            <dd>
              {{ spot.arrival_time }}
            </dd>
          </dl>

          <dl class="col-sm-4">
            <dt>{{ $t("SPOTS.SPOT_DEPARTURE_TIME") }}</dt>
            <dd>
              {{ spot.departure_time }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="campsite-sheet">
      <h3>{{ $t("SPOTS.SPOT_INCLUDED_EQUIPMENT") }}</h3>
      <div class="campsite-sheet-item">
        <div v-if="spot.spotType" class="equipments-included">
          <div class="equipments-included-group">
            <div v-for="eq in spot.other_equipments" :key="eq.id" class="item width_1-4">
              <ul>
                <li v-if="eq.icon">
                  <el-image
                      style="width: 20px; height: 20px"
                      :src="eq.icon"
                      fit="scale-down"
                      alt="icon"
                  />
                </li>
                <li>{{ eq.name }}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  Image,
} from "element-ui";

export default {
  name: "spot-view-sheet",

  props: ["spot"],

  components: {[Image.name]: Image},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    spotUpdated() {
      this.$emit("spotUpdated", true);
    },
  },

  mounted() {},

  watch: {
    spot(spot) {},
  },
};
</script>
