
export default {
  type: "spots",
  name: null,
  gallery: [],
  excerpt: "",
  status: null,
  clean_condition: null,
  unit_price: null,
  capacity: null,
  adults_capacity: null,
  children_capacity: null,
  pets_capacity: null,
  equipment_length: null,
  spot_area: null,
  passenger_extensions: null,
  driver_extensions: null,
  allowed_equipment: [],
  beds_king_qty: null,
  beds_queen_qty: null,
  beds_double_qty: null,
  beds_twin_qty: null,
  bathroom_shower_qty: null,
  bathroom_bathtub_qty: null,
  arrival_time: null,
  departure_time: null,
  created_at: null,
  updated_at: null,
  relationshipNames: [
    "organization",
    "category",
    "spotType",
    "other_equipments",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  category: {
    type: "spot-categories",
    id: null,
  },
  spotType: {
    type: "spot-types",
    id: "",
  },
  equipments: [],
  other_equipments: [],
  createdBy: {
    type: "user",
    id: "",
  },
};
